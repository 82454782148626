import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  employeeChange = new Subject<any>();
  changeChartTestPype = new Subject<number>(); // change test type on homepage

  constructor(private http: HttpClient) {
  }

  getHomeData(tesTypeId: number = 1): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/graph/homePage/${tesTypeId}`);
  }

  getCertType(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/certType/all`);
  }

  certificateStore(formData: any): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}/certificate/store`, formData).pipe(map(r => {
      this.employeeChange.next(r);
      return r;
    }));
  }
}
