import { Injectable } from '@angular/core';
import {SecurityData} from '../models/domain/security';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/securityMotion/all`);
  }

  getById(id: number): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/securityMotion/${id}`);
  }
}
